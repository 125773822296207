exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-agence-kanji-js": () => import("./../../../src/pages/agence-kanji.js" /* webpackChunkName: "component---src-pages-agence-kanji-js" */),
  "component---src-pages-bench-js": () => import("./../../../src/pages/bench.js" /* webpackChunkName: "component---src-pages-bench-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-lacoste-checkout-refit-js": () => import("./../../../src/pages/lacoste-checkout-refit.js" /* webpackChunkName: "component---src-pages-lacoste-checkout-refit-js" */),
  "component---src-pages-lacoste-design-system-js": () => import("./../../../src/pages/lacoste-design-system.js" /* webpackChunkName: "component---src-pages-lacoste-design-system-js" */),
  "component---src-pages-todo-js": () => import("./../../../src/pages/todo.js" /* webpackChunkName: "component---src-pages-todo-js" */),
  "component---src-pages-valbiotis-js": () => import("./../../../src/pages/valbiotis.js" /* webpackChunkName: "component---src-pages-valbiotis-js" */)
}

